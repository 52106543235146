<div>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'header.ticket_title' | translate }}</h4>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="manageAlertForm" (ngSubmit)="onSubmitClick()">
            <div class="text-inside">
                <!-- <div class="row">
                    <div class="m-l-20 m-t-20">
                        <p class="text-muted">Action: send an email</p>
                    </div>
                </div> -->
                <div class="row">
                    <div class="m-l-20 m-t-20">
                        <p>{{'header.ticket_tool' | translate}}:</p>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{'patient_information.alerts_tab.modal.select' | translate}}</mat-label>
                        <mat-select formControlName="tool" required class="example-full-width " appearance="outline">
                            <mat-option *ngFor="let gender of genders" [value]="gender">{{ 'header.' + gender | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
            
                <div *ngIf="manageAlertForm.getRawValue().tool== 'Mobile App'" class="row">
                    <div class="m-l-20 m-t-20">
                        <p>{{'header.ticket_category' | translate}}:</p>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{'patient_information.alerts_tab.modal.select' | translate}}</mat-label>
                        <mat-select formControlName="topic" required class="example-full-width " appearance="outline">
                            <mat-option *ngFor="let action of actionList" [value]="action">{{ 'header.' + action | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>

                <div *ngIf="manageAlertForm.getRawValue().tool== 'Questionnaires Web'" class="row">
                    <div class="m-l-20 m-t-20">
                        <p>{{'header.ticket_category' | translate}}:</p>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{'patient_information.alerts_tab.modal.select' | translate}}</mat-label>
                        <mat-select formControlName="topic" required class="example-full-width " appearance="outline">
                            <mat-option *ngFor="let action1 of actionList1" [value]="action1">{{ 'header.' + action1 | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{'header.ticket_description' | translate}}</mat-label>
                        <textarea formControlName="comments" matInput></textarea>
                      </mat-form-field>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
                <button mat-button type="button" (click)="closeDialog()">{{'patient_information.alerts_tab.modal.cancel' | translate}}</button>
                <button mat-raised-button type="button" color="primary" (click)="save()" [disabled]="!manageAlertForm.valid">{{'patient_information.alerts_tab.modal.save' | translate}}</button>
            </div>
        </form>
            
    </div>
</div>
