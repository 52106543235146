import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
declare var require: any
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  isTblLoading = true;

  constructor(private httpClient: HttpClient) { }

  /** CRUD METHODS */
  
}
