import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
declare var require: any
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RightSidebarUserService {
  isTblLoading = true;
  token = localStorage.getItem('access_token');
  private user = new Subject<any>();
  user$ = this.user.asObservable();
  private user_email = new Subject<any>();
  user_email$ = this.user_email.asObservable();

  constructor(private httpClient: HttpClient) { }
  /** CRUD METHODS */
  // getUser() {
  //   //get doctor identifier
  //   let user = localStorage.getItem("username")
  //   let url = environment.API_URL + "/CliniciansAPI/1.0.0/user?user_account[eq]="+user;
  //   this.httpClient.get<any[]>(url, {headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`)})
  //   .subscribe(  
  //     data => {
        
  //       this.isTblLoading = false;
  //       this.user.next(data[0]);
  //       (error: HttpErrorResponse) => {
  //         this.isTblLoading = false;
  //         console.log(error.name + " " + error.message);
  //       }
  //   });
  // }
 
  loginMasterClinicians(axios, qs){
    let clinician_username = localStorage.getItem("username")

    var url =environment.KEYCLOAK_URL 
    var clinicianBodyReq = qs.stringify({
        grant_type:'client_credentials',
        client_id:'PoCtool',
        client_secret:'d3ae8b20-0d00-4ff0-b5a2-2223e0c36fe1',
        username:'cliniciansadmin',
        password:'123'
      })


    var config ={
      method : 'POST',
      url:url,
      headers:{
        'Content-type':'application/x-www-form-urlencoded',
        'Accept':'application/json'
      },
      data : clinicianBodyReq
    }
    axios(config)
    .then(response =>{
      this.getUserEmail(response.data.access_token, clinician_username, axios)
    })  
  }
  getUserEmail(token, username, axios){
    var url =environment.KEYCLOAK_2+"/users?username="+username

    this.httpClient.get<any[]>(url, {headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)})
    .subscribe(  
      data => {
        
        this.isTblLoading = false;
        this.user_email.next(data[0].email);

        (error: HttpErrorResponse) => {
          this.isTblLoading = false;
          console.log(error.name + " " + error.message);
        }
    });
  }
}
