import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from 'src/app/core/service/auth.service';
import { Role } from 'src/app/core/models/role';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit {
  public sidebarItems: any[];
  showMenu = 'dashboard';
  showMenu_array = ['data-collection'];
  selected : any = "Patient Information"
  selected_submenu : any = "Patient List"
  showSubMenu = '';
  showSubSubMenu = '';
  url;
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,

  ) {
    const body = this.elementRef.nativeElement.closest('body');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.split('/')[1] === 'multilevel') {
          this.showMenu = event.url.split('/')[1];
        } else {
          this.showMenu = event.url.split('/').slice(-2)[0];
        }
        this.showSubMenu = event.url.split('/').slice(-2)[0];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  //open and close a menu
  callMenuToggle(event: any, element: any) {

    console.log("ELEMENT :",element)
    // if (element === this.showMenu) {
    //   this.showMenu = '0';
    // } else {
    //   this.showMenu = element;
    // }

    if(element=='dashboard'){
      this.selected_submenu=''
    }
    if (this.showMenu_array.indexOf(element)>-1) {
      this.showMenu = '0';
      this.showMenu_array.splice(this.showMenu_array.indexOf(element),1)
    } else {
      this.showMenu_array.push(element)
      this.showMenu = element;
    }

    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }
  callSubMenuToggle(event: any, element: any,title) {
    console.log("SUBMENU :",this.showSubMenu)
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }

    // this.selected_submenu = title;
    // if(this.selected_submenu=="Patient List" || this.selected_submenu=="Quality Check Summary"){
    //   this.selected = "Patient Information"

    // }else if(this.selected_submenu=="Pending Visits" || this.selected_submenu=="All visits"){
    //   this.selected = "Visit Management"

    // }else if(this.selected_submenu=="Alerts" || this.selected_submenu=="Messages unrecognized"){
    //   this.selected = "Alert Management"

    // }
    console.log("selected submenu :",this.selected_submenu)
  }
  ngOnInit() {
    this.router.navigate(['home/home'])
    this.selected = "Home"
    console.log(this.authService.currentUserValue)
    var currentUser= JSON.parse(localStorage.getItem("currentUserValue"))
    const userType = localStorage.getItem('userType');
    
    if (currentUser) {
      const userRole = currentUser.role;
      this.userFullName =
        currentUser.firstName +
        ' ' +
        currentUser.lastName;
      this.userImg = currentUser.img;
      
      this.sidebarItems = ROUTES.filter(
        (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
      );
      if(userType=="clinician") {
        this.sidebarItems = this.sidebarItems.filter(item => (item.title == "Home" || item.title == "About the study" ||
        item.title == "Research Information" || item.title == "Patient Information" || item.title == "Contact us"));
      }
      if(userType=="ticketing") {
        this.sidebarItems = this.sidebarItems.filter(item => (item.title == "Home" || item.title == "About the study" ||
        item.title == "Research Information" || item.title == "Ticketing" || item.title == "Patient Information" || item.title == "Contact us"));
      }
      if(userType=="patient") {
        this.sidebarItems = this.sidebarItems.filter(item => (item.title == "Home" || item.title == "About the study" ||
        item.title == "Patient Information" || item.title == "Contact us"));
      }
      if (userRole === Role.Admin) {
        this.userType = Role.Admin;
      } else if (userRole === Role.Patient) {
        this.userType = Role.Patient;
      } else if (userRole === Role.Doctor) {
        this.userType = Role.Doctor;
      } else {
        this.userType = Role.Admin;
      }
    }

    // this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  navigate(route,title){
    console.log("title :",title)
    this.selected = title

    if(this.selected=='Patient Information'){
      this.selected_submenu="Patient List"

    }else if(this.selected=='Visit Management'){
      this.selected_submenu="Pending Visits"
      
    }else if(this.selected=='Alert Management'){
      this.selected_submenu="Alerts"

    }
    this.router.navigate([route])
  }
}
