<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <!--<a class="navbar-brand" routerLink={{homePage}}>
        <img src="assets/images/BD4_logo.png" alt="" />
          <span class="logo-name">BD4QoL</span>
      </a>-->
      <a class="navbar-brand" routerLink={{homePage}}>
        <img class="sidebar-user-panel" src="assets/images/BD4_logo.png" alt="" /> 
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <!-- <mat-icon>menu</mat-icon> -->
            <img style="width: 25px;" [ngStyle]="{'transform':isNavbarCollapsed === true ? 'rotate(-180deg)' : 'rotate(0deg)' }" src="assets/images/colapse.png" />
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <!-- #END# Full Screen Button -->

        <!-- #START# User Profile -->
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-2">
            <mat-icon id="settingBtn">person_outline</mat-icon>
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;" (click)="toggleRightSidebar()">
                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>{{'header.profile' | translate}}
                        <!-- <button mat-icon-button class="mr-2">
                          <mat-icon >person</mat-icon> {{'header.profile' | translate}}
                        </button> -->
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2">feedback</mat-icon>Feedback
                      </a>
                    </li> -->
                    <li>
                      <a href="#" onClick="return false;" (click)="help()">
                        <mat-icon aria-hidden="false" class="mr-2">help</mat-icon>{{'header.manual' | translate}}
                      </a>
                    </li>
                    <!--
                      <span class="material-icons">
                      menu_book
                      </span>
                    -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>{{'header.logout' | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li *ngIf="userType == 'patient'" class="fullscreen">
          <button mat-icon-button (click)="openDialogTicket()" class=" nav-notification-icons">
            <mat-icon>warning</mat-icon>
          </button>
        </li> 
        <!-- #END# User profile -->
        <!-- MANUAL -->
        <!-- <li class="fullscreen">
          <button mat-icon-button disabled style="cursor: no-drop;">
            <mat-icon id="settingBtn" tooltipClass="tooltip_custom">menu_book</mat-icon>
          </button>
        </li> -->
        <!-- NOTIFICATIONS -->
        <!-- <li class="pull-right"> -->
         <!-- <button mat-icon-button class="nav-notification-icons js-right-sidebar">
            <mat-icon id="settingBtn">settings</mat-icon>
          </button> -->
          <!-- <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar">
            <mat-icon id="settingBtn">settings</mat-icon>
          </button> -->
        <!-- </li> -->
      </ul>
    </div>
  </div>
</nav>
