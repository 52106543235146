<!-- Right Sidebar -->
<aside id="rightsidebar" class="right-sidebar" [ngClass]="isOpenSidebar ? 'open' : 'close'"
  (clickOutside)="onClickedOutside($event)">
  <div class="rightSidebarClose" (click)="toggleRightSidebar()"><i class="far fa-times-circle"></i></div>
  <div class="demo-skin"
    [ngStyle]="{'position' : 'relative', 'max-height' : maxHeight+'px', 'max-width' : maxWidth+'px'}"
    [perfectScrollbar]>
    <div class="rightSetting">
       
      <div class="user-panel">
        <div class=" image">
          <img class="img-circle user-img-circle" src="assets/images/profile2.png" alt="" />
        </div>
      </div>
      <div class="profile-usertitle m-t-20">
        <div class="sidebar-userpic-name">{{userName}}</div>
      </div>
      <br>
      <p>{{ 'header.right_bar.general_information' | translate }}</p>
      <ul class="setting-list list-unstyled m-t-20">
        <li>
          <div class="form-check">
            <div class="form-check m-l-10">
              <label class="form-check-label">
                <div><strong>{{ 'header.right_bar.username' | translate }}: </strong>{{userUsername}}</div>
              </label>
            </div>
          </div>
        </li>
        <li>
          <div class="form-check">
            <div class="form-check m-l-10">
              <label class="form-check-label">
                <div><strong>{{ 'header.right_bar.email' | translate }}: </strong>{{userEmail}}</div>
              </label>
            </div>
          </div>
        </li>
        <li>
          <div class="form-check">
            <div class="form-check m-l-10">
              <label class="form-check-label">
                <!-- <div><strong>{{ 'header.right_bar.study_center' | translate }}: </strong>{{userCenter}}</div> -->
              </label>
            </div>
          </div>
        </li>
        
      </ul>
      <div style="margin-top: 50px;">
        <button mat-raised-button (click)="changePassword()" style="width: 100%;" color="primary">{{ 'header.right_bar.change_password' | translate }}</button>      
      </div>

    </div>
  </div>
</aside>
<!-- #END# Right Sidebar -->
