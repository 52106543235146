import { Component } from '@angular/core';
import {
  Event,
  Router,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from '@angular/common';
import { PreviousUrlService } from './patient/patient-data/previous-url.service';
import {TranslateService} from '@ngx-translate/core';


declare let gtag: Function; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUrl: string;
  constructor(public _router: Router, location: PlatformLocation, private spinner: NgxSpinnerService,
    private previousUrlService : PreviousUrlService, private translate: TranslateService) {
      // title: 'Multilanguage';
      // This will be used to set the default language
      // translate.setDefaultLang('en');
      if(localStorage.getItem('language')){
        this.translate.use(localStorage.getItem('language'));
      }else{
        this.translate.use('en');
      }

    // this.previousUrlService.getPreviousUrl()

    this._router.events.subscribe(event => {
      if(event instanceof NavigationEnd){

       let usuario = localStorage.getItem("username")
       console.log("USUARIO :",usuario)
       if(usuario!=null){
          gtag('config', 'G-W5Z1NSDTRL', 
          {
            'page_path': event.urlAfterRedirects,
            'page_title': event.urlAfterRedirects,
            'ga_session_id': usuario
          }
        );
       }
          
       }
    })




    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        // location.onPopState(() => {
        //   window.location.reload();
        // });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }






}
