import { Component, OnInit, Injectable, Inject, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { stringify } from '@angular/compiler/src/util';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
declare var require: any
@Component({
  selector: 'app-ticketform',
  templateUrl: './ticketform.component.html',
  styleUrls: ['./ticketform.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TicketformComponent),
      multi: true
    }
  ]
})
export class TicketformComponent implements OnInit {
  public manageAlertForm: FormGroup;
  token = localStorage.getItem('access_token');
  user = localStorage.getItem('username');
  //Radio Button
  gender:string;
  genders: string[] = ['Mobile App', 'Questionnaires Web'];

  //Multi Select
  actions = new FormControl();
  actionSelected:string;
  actionList: string[] = ['Steps', 'Phone', 'Alerts', 'Other'];
  actions1 = new FormControl();
  actionSelected1:string;
  actionList1: string[] = ['CBIB', 'QLQ', 'Health', 'Head and Neck', 'Care', "Other"];
  //action= "Email"
  sendThroughs = new FormControl();
  sendThroughList: string[] = ['Chatbot', 'Email'];
  id: string;
  id_alert: number;
  
  constructor(  
    private router:Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    //private snackBar: MatSnackBar,
    private httpClient: HttpClient

    ) {
    
   }

  ngOnInit(): void {
    this.manageAlertForm = this.fb.group({
      
      tool:["", [Validators.required]],
      topic: ["", [Validators.required]],
      comments: "",
     
    });
    const body2 = new HttpParams()
    .set('grant_type', 'client_credentials')
    .set('client_secret', 'd3ae8b20-0d00-4ff0-b5a2-2223e0c36fe1')
    .set('client_id', 'PoCtool');


    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    
    const auxiliar=this.httpClient.post<any>(environment.KEYCLOAK_URL ,body2.toString(),httpOptions)
    auxiliar.subscribe(data=>{

      localStorage.setItem("access_token2",data.access_token)

      
        
    });


  }
  showNotification(colorName, text, placementFrom, placementAlign) {
    /*this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName
    });*/
  }
  closeDialog(): void {
    //gtag('event', 'individual-patient-data/alert-report/manage-alert/close')
    this.dialog.closeAll();
  }
  save(): void {

   // gtag('event', 'individual-patient-data/alert-report/manage-alert/save')
 
    var axios = require('axios');
    var data = JSON.stringify({
      "elements": [
        {
          "study_id": this.user,
          "tool": this.manageAlertForm.getRawValue().tool,
          "submission_date": new Date(), 
          "topic": this.manageAlertForm.getRawValue().topic,
          "status": "unassigned",
         "description": this.manageAlertForm.getRawValue().comments,
        }
      ]
    });

    console.log(data)

    var config = {
      method: 'post',
      url: environment.API_URL+'/CliniciansAPI/1.0.0/tickets',
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("access_token2"), 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(response => {
      console.log(JSON.stringify(response.data));
      this.dialog.closeAll();
      this.showNotification(
        "black",
        "Error Report Filled Successfully",
        "bottom",
        "center"
      );
    })
    .catch(function (error) {
      console.log(error);
    });
    
    
    
  }
  goToPage(pageName:string):void{
    this.router.navigate([pageName]);
  }
  onSubmitClick() {
    console.log('Form Value', this.manageAlertForm.value);
  }
  manageAlert() {
    // let interventionActions = this.manageAlertForm.get('action').value;
    // let interventionComment = this.manageAlertForm.get('comments').value
    // console.log(interventionActions)
    // console.log("Comments", interventionComment)
   // this.alertReportService.addNewIntervention(this.manageAlertForm.getRawValue());

    // console.log(this.addPatientForm.getRawValue())
    // this.dataVisualizationService.addPatients(this.addPatientForm.getRawValue());
    // this.dataVisualizationService.addDoctors(this.addPatientForm.getRawValue());
  }

}
