// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   API_URL : 'https://test-apigw.bd4qoldatahub.eu',
//  WSO2_URL : 'https://test.apiM.bd4qoldatahub.eu',
//  KEYCLOAK_URL: 'https://test.bd4qoliam.eu/auth/realms/Clinicians/protocol/openid-connect/token',
//  KEYCLOAK_2: "https://test.bd4qoliam.eu/auth/admin/realms/Clinicians",
//  REDCAP_URL: "https://redcap.cesvima.upm.es/redcap_v12.0.2/ProjectSetup/index.php?pid=135",
//   production: false,

// };
export const environment = {
  API_URL : 'https://apigw.bd4qoldatahub.eu',
  WSO2_URL : 'https://apiM.bd4qoldatahub.eu',
  KEYCLOAK_URL: 'https://bd4qoliam.eu/auth/realms/Clinicians/protocol/openid-connect/token',
  REDCAP_URL: "https://redcap.cesvima.upm.es/redcap_v12.0.2/ProjectSetup/index.php?pid=117",
  KEYCLOAK_2: "https://bd4qoliam.eu/auth/admin/realms/Clinicians",

   production: false,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
