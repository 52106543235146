import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';
import { jsDocComment } from '@angular/compiler';

declare let gtag: Function;
declare var require: any



@Injectable({
  providedIn: 'root',
})
export class AuthService {

  logged_user : any;
  urlCenter: any;
//   cliniciansUsers= ["amanocchio", "cresteghini", "scavalieri", "ecolombo", "fcaspani", "llacerenza", "amanfuso",
// "llicitra", "inuzzolese", "rlombardo", "ipellegrini", "itaglialatela", "cpagliuca",
// "emartinelli", "ccopelli", "fgiuliani", "khurley", "stoms", "gjones", "gryan", "jjones",
// "aabou", "jbrooks", "l.lopez"]
  
//   ticketingUsers= ["bd4qolin", "bd4tecnicomi", "fricciardi", "sistemi.informativi", "llopez",
//     "vvicente", "eestevez", "ialonso", "aljavie7", "krzyszto", "bmaguire", "abilbao", "bd4qol-hub",
//     "jgago", "lruiz", "dcampanero", "fmercalli", "aalmeida", "echristelis", "efilippidis", "amanos", "gfourkas", "npertselis"]

  userType: string;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  currentUserSesion;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string, userTypeValue: string) {
    console.log("userTypeValue", userTypeValue);
    
    let body2 = new HttpParams()
    let urlToken
    // .set('username', username)
    // .set('password', password)
    // .set('grant_type', 'password')
    // .set('client_secret', 'd3ae8b20-0d00-4ff0-b5a2-2223e0c36fe1')
    // .set('client_id', 'PoCtool');
    if(userTypeValue == "ticketing") {
      body2 = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_secret', '7c99939e-b39b-4e3a-be73-ac8dd8c3240e')
      .set('client_id', 'ParticipantPortal');
      urlToken = "https://test.bd4qoliam.eu/auth/realms/PortalUsers/protocol/openid-connect/token"
      console.log("ticketing conf");
      
    }
    if(userTypeValue == "clinician") {
      body2 = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_secret', 'd3ae8b20-0d00-4ff0-b5a2-2223e0c36fe1')
      .set('client_id', 'PoCtool');
      urlToken = 'https://bd4qoliam.eu/auth/realms/Clinicians/protocol/openid-connect/token'
      console.log("clinicians conf");

    }
    if(userTypeValue == "patient") {
      body2 = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_secret', 'f8416dcf-f690-419c-8c14-57a524449ddf')
      .set('client_id', 'PoCtool');
      urlToken = "https://bd4qoliam.eu/auth/realms/Patients/protocol/openid-connect/token"
      console.log("patients conf");

    }
    

    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      })
      };
    
      // const auxiliar=this.http.post<any>('https://bd4qoliam.eu/auth/realms/Clinicians/protocol/openid-connect/token',body2.toString(),httpOptions)
      // const auxiliar=this.http.post<any>(environment.KEYCLOAK_URL ,body2.toString(),httpOptions)
      const auxiliar=this.http.post<any>(urlToken, body2.toString(), httpOptions)
      console.log("urlToken", urlToken);
      
      // const auxiliar=this.http.post<any>('https://bd4qoliam.eu/auth/realms/apim/protocol/openid-connect/token',body2.toString(),httpOptions)
      auxiliar.subscribe(data=>{
        console.log(data)
        localStorage.setItem('access_token', data.access_token);
        var fakeUser : User={
          id: 1,
          img: "user",
          username: "user",
          firstName: "some",
          lastName: "some",
          role: Role.Admin,
        }

        this.currentUserSubject.next(fakeUser);
        this.currentUserSesion=fakeUser;
        localStorage.setItem('currentUserValue', JSON.stringify(fakeUser));

        //For google analytics
        localStorage.setItem("username",username)

        //Store type of user (clinician, ticketing or patient)
        // if (this.cliniciansUsers.includes(username)) {
        //   this.userType = "clinician"
        // } else if (this.ticketingUsers.includes(username)) {
        //   this.userType = "ticketing"
        // } else {
        //   this.userType = "patient"
        // }
        localStorage.setItem("userType",userTypeValue)

        gtag('event', 'login')

        
      },error=>{
        console.log("-> ",error.error)
      })

    return auxiliar
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  getUser(){
    if(this.logged_user){
      console.log("USER LOGGED :",this.logged_user)
      return this.logged_user;
    }else{
      console.log("NO USER ")
    }
    
  }
  timerInactivity(dornetHelper) {
    var timer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function resetTimer() {
      clearTimeout(timer);
      timer = setTimeout(this.logout, 3000);
    }
    function logout() {
      dornetHelper.invokeMethodAsync("Logout")
    }

  }

}
