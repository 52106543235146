import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ConfigService } from "src/app/config/config.service";
import { RightSidebarService } from "src/app/core/service/rightsidebar.service";
import { RightSidebarUserService } from './right-sidebar.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import {TranslateService} from '@ngx-translate/core';

declare var require: any

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-right-sidebar",
  templateUrl: "./right-sidebar.component.html",
  styleUrls: ["./right-sidebar.component.sass"],
})
export class RightSidebarComponent implements OnInit, AfterViewInit {
  selectedBgColor = "white";
  maxHeight: string;
  maxWidth: string;
  showpanel = false;
  isOpenSidebar: boolean;
  isDarkSidebar = false;
  isDarTheme = false;
  public config: any = {};
  userName: string;
  userEmail: string;
  userCenter: string;
  userUsername: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private userService: RightSidebarUserService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
  ) {}
  ngOnInit() {
    this.config = this.configService.configData;
    this.dataService.currentStatus.subscribe((data: boolean) => {
      this.isOpenSidebar = data;
    });
    this.userUsername = localStorage.getItem("username")
    //subscription
    // this.userService.user$.subscribe(
    //   data => {
    //     this.userName = data.name + " " + data.surname
    //     this.userUsername = data.user_account
    //     switch(data.id_study_center) {
    //       case 19:
    //         this.userCenter = "INT";
    //         break;
    //       case 20:
    //         this.userCenter = "CSS";
    //         break;
    //       case 21:
    //         this.userCenter = "Turin";
    //         break;
    //       case 25:
    //         this.userCenter = "Test";
    //         break;
    //       case 28:
    //         this.userCenter = "UoHB";
    //         break;
    //      }
    // });
    this.userService.user_email$.subscribe(
      data => {
        this.userEmail = data
    });



    //Get user
    // this.userService.getUser()
    this.setRightSidebarWindowHeight();
    var axios = require('axios');
    var qs = require('qs');

    this.userService.loginMasterClinicians(axios, qs)
      // var masterUserToken = user.data.access_token
      // var userId = await this.userService.getUserEmail(masterUserToken, this.f.email.value , axios, qs)
  }

  ngAfterViewInit() {
    // set header color on startup
    if (localStorage.getItem("choose_skin")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_skin")
      );
      this.selectedBgColor = localStorage.getItem("choose_skin_active");
    } else {
      this.renderer.addClass(
        this.document.body,
        "theme-" + this.config.layout.theme_color
      );
      this.selectedBgColor = this.config.layout.theme_color;
    }

    if (localStorage.getItem("menuOption")) {
      if (localStorage.getItem("menuOption") === "menu_dark") {
        this.isDarkSidebar = true;
      } else if (localStorage.getItem("menuOption") === "menu_light") {
        this.isDarkSidebar = false;
      } else {
        this.isDarkSidebar =
          this.config.layout.sidebar.backgroundColor === "dark" ? true : false;
      }
    } else {
      this.isDarkSidebar =
        this.config.layout.sidebar.backgroundColor === "dark" ? true : false;
    }

    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "dark") {
        this.isDarTheme = true;
      } else if (localStorage.getItem("theme") === "light") {
        this.isDarTheme = false;
      } else {
        this.isDarTheme = this.config.layout.variant === "dark" ? true : false;
      }
    } else {
      this.isDarTheme = this.config.layout.variant === "dark" ? true : false;
    }
  }

  selectTheme(e) {
    this.selectedBgColor = e;
    const prevTheme = this.elementRef.nativeElement
      .querySelector(".right-sidebar .demo-choose-skin li.actived")
      .getAttribute("data-theme");
    this.renderer.removeClass(this.document.body, "theme-" + prevTheme);
    this.renderer.addClass(this.document.body, "theme-" + this.selectedBgColor);
    localStorage.setItem("choose_skin", "theme-" + this.selectedBgColor);
    localStorage.setItem("choose_skin_active", this.selectedBgColor);
  }
  lightSidebarBtnClick() {
    this.renderer.removeClass(this.document.body, "menu_dark");
    this.renderer.removeClass(this.document.body, "logo-black");
    this.renderer.addClass(this.document.body, "menu_light");
    this.renderer.addClass(this.document.body, "logo-white");
    const menuOption = "menu_light";
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("menuOption", menuOption);
  }
  darkSidebarBtnClick() {
    this.renderer.removeClass(this.document.body, "menu_light");
    this.renderer.removeClass(this.document.body, "logo-white");
    this.renderer.addClass(this.document.body, "menu_dark");
    this.renderer.addClass(this.document.body, "logo-black");
    const menuOption = "menu_dark";
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("menuOption", menuOption);
  }
  lightThemeBtnClick() {
    this.renderer.removeClass(this.document.body, "dark");
    this.renderer.removeClass(this.document.body, "submenu-closed");
    this.renderer.removeClass(this.document.body, "menu_dark");
    this.renderer.removeClass(this.document.body, "logo-black");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem("choose_skin")
      );
      this.renderer.addClass(this.document.body, "theme-white");
    }

    this.renderer.addClass(this.document.body, "light");
    this.renderer.addClass(this.document.body, "submenu-closed");
    this.renderer.addClass(this.document.body, "menu_light");
    this.renderer.addClass(this.document.body, "logo-white");
    const theme = "light";
    const menuOption = "menu_light";
    this.selectedBgColor = "white";
    this.isDarkSidebar = false;
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("choose_skin", "theme-white");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);
  }
  darkThemeBtnClick() {
    this.renderer.removeClass(this.document.body, "light");
    this.renderer.removeClass(this.document.body, "submenu-closed");
    this.renderer.removeClass(this.document.body, "menu_light");
    this.renderer.removeClass(this.document.body, "logo-white");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem("choose_skin")
      );
      this.renderer.addClass(this.document.body, "theme-black");
    }
    this.renderer.addClass(this.document.body, "dark");
    this.renderer.addClass(this.document.body, "submenu-closed");
    this.renderer.addClass(this.document.body, "menu_dark");
    this.renderer.addClass(this.document.body, "logo-black");
    const theme = "dark";
    const menuOption = "menu_dark";
    this.selectedBgColor = "black";
    this.isDarkSidebar = true;
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("choose_skin", "theme-black");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);
  }
  setRightSidebarWindowHeight() {
    const height = window.innerHeight - 137;
    this.maxHeight = height + "";
    this.maxWidth = "500px";
  }
  onClickedOutside(event: Event) {
    const button = event.target as HTMLButtonElement;
    // if (button.id !== "settingBtn") {
    //   if (this.dataService.currentStatus._isScalar === true) {
    //     this.toggleRightSidebar();
    //   }
    // }
  }
  toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }
  changePassword() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/forgot-password']);
        localStorage.removeItem('access_token')
        localStorage.removeItem('currentUserValue')
      }
    });
  }
}
