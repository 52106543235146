import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { HeaderService } from './header.service';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription } from "rxjs";
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { TicketformComponent } from '../ticketform/ticketform.component';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public config: any = {};
  userImg: string;
  homePage: string; 
  isNavbarCollapsed = false;
  id: string;
  notification_type: string;
  userRole: any;
  userType: any;
  //Observables

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public notifications_service: HeaderService,
    private dialogModel: MatDialog,
    // public dropDown: NgbDropdown


  ) {}
  notifications: any[] = [];
  ngOnInit() {


    this.config = this.configService.configData;
     this.userRole = JSON.parse(localStorage.getItem("currentUserValue")).role;
    this.userImg = JSON.parse(localStorage.getItem("currentUserValue")).img;
    this.userType = localStorage.getItem('userType');
    if (this.userRole === 'Admin') {
      this.homePage = 'home/home';
    } else if (this.userRole === 'Patient') {
      this.homePage = 'patient/dashboard';
    } else if (this.userRole === 'Doctor') {
      this.homePage = 'doctor/dashboard';
    } else {
      this.homePage = 'admin/dashboard/main';
    }

  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed
    console.log("IS NAV :",this.isNavbarCollapsed)
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      console.log("HAS CLASS")
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
        localStorage.removeItem('access_token')
        localStorage.removeItem('currentUserValue')
      }
    });
  }
  help(){
    window.open('assets/pdf/BD4QoL UserManual PARTICIPANT_MobileApp and Quesionnaires.pdf','_blank')
  }
  goToPage(pageName:string):void{
    this.router.navigate([pageName], { queryParams: { id: this.id, notification_type: this.notification_type} });

  }
  openDialogTicket(): void {
    
    const dialogRef = this.dialogModel.open(TicketformComponent, {
      width: "740px",
      disableClose: true,
    });
  }
}
