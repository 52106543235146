<div>
 
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <!-- Top Most level menu -->
        <li [ngClass]="selected == sidebarItem.title ? 'activated' : 'not_activated'" [class.active]="showMenu_array.indexOf(sidebarItem.moduleName) >(-1)  && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of sidebarItems">
          <!-- <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div> -->
          <a id="main" style="cursor: pointer;" *ngIf="!sidebarItem.groupTitle;"
            [ngClass]="[sidebarItem.class]" (click)="callMenuToggle($event, sidebarItem.moduleName)" class="menu-top">
            <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}} </i>
            <span  class="hide-menu" (click)="navigate(sidebarItem.path,sidebarItem.title)">{{ 'sidebar_items.' + sidebarItem.title | translate }}
              <!-- {{sidebarItem.title}} -->
            </span>
            <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
          </a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li  *ngFor="let sidebarSubItem of sidebarItem.submenu" [ngClass]="selected_submenu == sidebarSubItem.title ? 'activated_sub' : 'not_activated_sub'"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callSubMenuToggle($event,sidebarSubItem.moduleName,sidebarSubItem.title)" [ngClass]="[sidebarSubItem.class]">
                {{ 'sidebar_items.' + sidebarSubItem.title | translate }}
                <!-- {{sidebarSubItem.title}} -->
              </a>
              <!-- Second level menu -->
              <!-- <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="showSubSubMenu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title}}
                  </a>
                </li>
              </ul> -->
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>