import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '',
  //   title: '',
  //   moduleName: '',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [],
  // },

  // Admin Modules (de momento estan aqui todos)
  
  {
    path: '/home/home',
    title: 'Home',
    moduleName: 'home',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
 
  /*
  {
    path: '/prueba/prueba',
    title: 'prueba',
    moduleName: 'prueba',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },*/
  {
    path: '/about/about',
    title: 'About the study',
    moduleName: 'about',
    iconType: 'material-icons-two-tone',
    icon: 'info',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/patient-information/patient-information',
    title: 'Patient Information',
    moduleName: 'patient-information',
    iconType: 'material-icons-two-tone',
    icon: 'people',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/research-information/research-information',
    title: 'Research Information',
    moduleName: 'research-information',
    iconType: 'material-icons-two-tone',
    icon: 'query_stats',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/ticketing/ticketing',
    title: 'Ticketing',
    moduleName: 'ticketing',
    iconType: 'material-icons-two-tone',
    icon: 'announcement',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/contact-us/contact-us',
    title: 'Contact us',
    moduleName: 'contact-us',
    iconType: 'material-icons-two-tone',
    icon: 'mail',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },

];