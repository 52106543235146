import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { Role } from './core/models/role';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
    /*  {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },*/
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'prueba',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./prueba/prueba.module').then(
            (m) => m.PruebaModule
          ),
      },
      {
        path: 'about',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./about/about.module').then(
            (m) => m.AboutModule
          ),
      },
      {
        path: 'patient-information',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./patient-information/patient-information.module').then(
            (m) => m.PatientInformationModule
          ),
      },  
      {
        path: 'contact-us',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./contact-us/contact-us.module').then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: 'research-information',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./research-information/research-information.module').then(
            (m) => m.ResearchInformationModule
          ),
      },
      {
        path: 'ticketing',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./ticketing/ticketing.module').then(
            (m) => m.TicketingModule
          ),
      },
      {
        path: 'data-collection',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./data-collection/data-collection.module').then(
            (m) => m.DataCollectionModule
          ),
      },
      {
        path: 'data-collection',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./data-collection/data-collection.module').then(
            (m) => m.DataCollectionModule
          ),
      },
      {
        path: 'exploratory-dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./exploratory-dashboard/exploratory-dashboard.module').then(
            (m) => m.ExploratoryDashboardModule
          ),
      },
      {
        path: 'data-collection',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./data-collection/data-collection.module').then(
            (m) => m.DataCollectionModule
          ),
      },
      {
        path: 'alert-management',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./alert-management/alert-management.module').then(
            (m) => m.AlertManagementModule
          ),
      },
      {
        path: 'visit-dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./visit-dashboard/visit-dashboard.module').then(
            (m) => m.VisitDashboardModule
          ),
      },
     
      {
        path: 'doctor',
        canActivate: [AuthGuard],
        data: {
          role: Role.Doctor,
        },
        loadChildren: () =>
          import('./doctor/doctor.module').then((m) => m.DoctorModule),
      },
      {
        path: 'patient',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./patient/patient.module').then((m) => m.PatientModule),
      },
      {
        path: 'extra-pages',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'multilevel',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./multilevel/multilevel.module').then(
            (m) => m.MultilevelModule
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
